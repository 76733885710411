import Practice from "../components/pages/Practice";
import React from "react";
import Project from "../components/pages/Project";
import Features from "../components/pages/Features";

export const tabBody = {
    project: {
        title: 'A NEW WAY TO BRING YOUR POINT',
        text: <Project/>
    },
    practice: {
      title:'WHERE TO APPLY?',
      text: <Practice/>
    },
    features: {
        title: 'WHY IT\'S GREAT?',
        text: <Features/>
    },
    about: {
        title: 'ABOUT US',
        text:'We are a cool dream team and we believe that we can bring something great to our world.'
    }
};
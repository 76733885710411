import React from 'react';

const Features = () => {

    return (
        <div>
            <ol>
                <li>Easy-managed plugin</li>
                <li>Automatically pre-moderated</li>
                <li>Installed on any website</li>
                <li>Provides threads of discussions</li>
                <li>Content management for moderators</li>
            </ol>
        </div>
    );
};


export default Features;
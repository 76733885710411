import React  from 'react';

const Project = () => {

    return (
        <div>
            <p>
                &nbsp;<span className={'talk-chat'}><span className={'word-talk-chat'}>V</span>ideo <span
                className={'word-talk-chat'}>T</span>alk <span className={'word-talk-chat'}>C</span>hat </span>
                is a new service, that provides surfers the possibility to
                response by self-recorded video and join it to a non-real-time video
                conversations.
            <br/>
                &nbsp;It allows to leave a video comments and to create a video threads
                related to any content, that located on website, such as video clips,
                text articles, sale web pages etc.
            </p>
        </div>
    );
};


export default Project;

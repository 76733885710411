import React, {useEffect, useState} from 'react';
import Col from "react-bootstrap/Col";
import Plugin from "./Plugin";
import Cards from "./Cards";
import {tabBody} from "../constants/Constants";
import EmailSender from "./EmailSender";
import Footer from "./Footer";

const Main = (props) => {

    let page = props.match.params.key;
    if (page === undefined || !Object.keys(tabBody).includes(page)){
        page = 'project';
    }

    const[tabKey,setTabKey] = useState(page);
    const[message,setMessage] = useState('VideoTalkChat.com');

    const footerMessage = (message) => {
        setMessage(message);
    };

    useEffect(() => {
        setTabKey(page);
    },[page]);

    return (
        <div className={'container-fluid jumbotron'}>
            <div className={'row'}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <header className={'App-header'}>
                        <h1 className={'display-1'}>Vide<span className={'o-non'}>o</span>
                            <img src={`${process.env.PUBLIC_URL}/bobbin.svg`}
                                 className="App-logo"
                                 alt="logo"/>
                            Talk Chat
                        </h1>
                        <div className={'camera-soon-container'}>
                            <img src={`${process.env.PUBLIC_URL}/camera.svg`} className="camera" alt="camera"/>
                            <span
                                className={'soon'}
                            >Coming soon...</span>
                        </div>
                    </header>
                </Col>

                <Col xl={6} className={'App'}>
                    <Plugin/>
                </Col>

                <Col xl={6} className={'card-info'}>
                    <Cards changeTab={props.changeTab}
                            currentKey={tabKey}/>
                    <EmailSender sendMessage={footerMessage}/>
                    <Footer message={message} sendMessage={footerMessage}/>
                </Col>
            </div>
        </div>
    );
};

export default Main;
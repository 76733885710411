import React, {useEffect} from 'react';
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import {LinkContainer} from "react-router-bootstrap";
import {tabBody} from '../constants/Constants'

const Cards = (props) => {
    const handleSelect = (eventKey) => {
        props.changeTab(eventKey);
         };

    useEffect(() => {
        window.addEventListener("popstate", function (e) {
            window.location.reload();
        });
    },[]);

    return (
        <div>
            <Card>
                <Card.Header>
                    <Nav justify variant="tabs" defaultActiveKey={props.currentKey} onSelect={handleSelect}>
                        <Nav.Item>
                            <LinkContainer to={'/project'}><Nav.Link  eventKey="project">Project</Nav.Link></LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to={'/features'}><Nav.Link eventKey="features">Features</Nav.Link></LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to={'/practice'}><Nav.Link eventKey="practice">Practice</Nav.Link></LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to={'/about'}><Nav.Link eventKey="about">About us</Nav.Link></LinkContainer>
                        </Nav.Item>
                    </Nav>
                </Card.Header>

                <Card.Body>
                    <Card.Title>
                        {tabBody[props.currentKey] ? tabBody[props.currentKey].title : ''}
                    </Card.Title>
                    <div className={'card-text'}>
                        {tabBody[props.currentKey] ? tabBody[props.currentKey].text : ''}
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};


export default Cards;
import React from "react";
import {useEffect} from "react";

const Footer = (props) => {

    useEffect(() => {
        if (props.message !== 'VideoTalkChat.com') {
            const timer = setTimeout(() => {
                props.sendMessage('VideoTalkChat.com')
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [props, props.message]);

    const renderBrand = () => {
        return (
            <div className={'footer'} >
                <p>VideoTalkChat.com &#169;</p>
            </div>
        );
    };

    const renderMessage = () => {
        return (
            <div className={'footer'}>
                <h5 className={'mb-5'}>{props.message}</h5>
            </div>
        );
    };

    return props.message === 'VideoTalkChat.com' ? renderBrand() : renderMessage();

};

export default Footer;
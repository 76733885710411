import React, {Component} from 'react';

class Plugin extends Component {
    constructor(props) {
        super(props);
         this.id = "JDJhJDEwJHZ1Q0Y5UnJaZkQ1a2dQbmRpd2xEOE9VZXhIc0NkdWs2a0ZQZFV4TU9icVdNUDd2VjRNL215";
         this.frameRef = React.createRef();
    }

    handleContentWindow = () => {
         const iframe = this.frameRef.current;
         iframe.contentWindow.postMessage({"origin": window.location.href,"frameId": this.id}, 'https://plugin.videotalkchat.com');
    };

    render() {
        return (
            <div className={'pluginContainer'}>
                <iframe ref={this.frameRef}
                        title={'plugin'}
                        className={'plugin'}
                        src="https://plugin.videotalkchat.com"
                        onLoad={this.handleContentWindow}
                        allow="camera;microphone"
                        allowFullScreen />
            </div>
        );
    }
}

export default Plugin;
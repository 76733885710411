import * as React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import {useState} from "react";
import emailjs from "emailjs-com";

const FormCard = (props) => {
    const [validated, setValidated] = useState(false);

    function sendEmail(event) {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            emailjs.sendForm('gmail', 'video_talk_chat', event.target, 'user_iJYzROMyoCjO8II8dIeZ0')
               .then((result) => {
                   window.location.reload();
                    props.sendMessage("Your message was sent successfully");
               }, (error) => {
                   props.sendMessage("Something went wrong, try again");
               });
        }

    }

    return (
        <Form noValidate validated={validated} onSubmit={sendEmail} className={'form-card'}>
            <Form.Row>
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                    <Form.Control
                        required
                        type="text"
                        name="from_name"
                        placeholder="Name*"
                    />
                    <Form.Control.Feedback/>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                    <Form.Control
                        required
                        type="text"
                        name="from_email"
                        placeholder="Email*"
                    />
                    <Form.Control.Feedback/>
                </Form.Group>

            </Form.Row>
            <Form.Group className={'form-group-text'} controlId="exampleForm.ControlTextarea1">
                <Form.Control as="textarea"
                              rows="3"
                              name="message_html"
                              placeholder="How can we help? Ask us to get more info*"/>
            </Form.Group>
            <Button type='submit' variant="success" className={'send'}>SEND</Button>
        </Form>
    );
};

export default FormCard;
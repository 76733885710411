import React from 'react';
import './App.css';
import Main from "./components/Main";
import {tabBody} from "./constants/Constants";
import {Route, Switch} from "react-router";


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: tabBody['project']
        }
    }

    changeTab = (key) => {
        this.setState({
            tab: tabBody[key]
        })
    };

    render() {
        return (
            <Switch>
                <Route path={['/', '/:key']} exact
                       render={(props) => (<Main {...props} changeTab={this.changeTab}
                                                            tab={this.state.tab}/>)}/>
            </Switch>
        )
    }
}

export default App;

import React  from 'react';

const Practice = () => {

        return (
            <>
                <ol>
                    <li>Education Platforms <span className={'scope'}>(internal discussions)</span></li>
                    <li>Companies Portals <span className={'scope'}>(internal discussions)</span></li>
                    <li>News and Advertisement Websites <span className={'scope'}>(talkbacks)</span></li>
                    <li>Online Marketplaces <span className={'scope'}>(product reviews)</span></li>
                    <li>Blog Platforms <span className={'scope'}>(talkbacks and comments)</span></li>
                </ol>
            </>
        );
    };


export default Practice;